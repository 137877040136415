import React from "react";
import _ from "lodash";
import axios from "axios";

import TableCrud from "../TableCrud";
import { AssetsPopup } from "./AssetsPopup";
import Loading from "../Miscellaneous/Loading";

import { getMonth } from "../../../scripts/global";
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, MenuItem, Input, Button, Menu, Checkbox, TableSortLabel, Typography, TextField, DialogTitle, Dialog, List, ListItem, ListItemText } from "@material-ui/core";
import moment from 'moment';
import TableConfig from './MediaBuysConfig';
import MediaBuysFilters from "./MediaBuysFilters";
import ScrollTopLeft from "../Miscellaneous/scrollTopLeft";
import { statusOptions } from '../MediaBuy/provider';
import MediaBuyRequestProvider from '../MediaBuy/provider';

import { MediaCostUpdatePopup } from "./MediaCostUpdatePopup";

const hiddenColsDefault = [
    'vendor_budget',
    'variable_cpl',
    'send_leads_to_email_system',
    'add_incoming_leads',
    'workflow_id',
    'campaign_name',
    'interest_name',
    'medium',
    'type_id',
    'content_name',
    'keyword',
    'publisher_name',
    'affiliate_name',
    'guru_name',
    'vendor_budget',
    'projected_margin',
    'client_marketing_costs',
    'client_campaign',
    'realized_margin'
];

class MediaBuys extends React.Component {
    service = MediaBuyRequestProvider
    constructor(props) {
        super(props);
        this.state = {
            data: false,
            showAssetsPopup: false,
            showUpdatePopup: false,
            activeRow: {},
            defaultHiddenColumns: [...hiddenColsDefault],
            statusDialogOpened: false,
            columnsAnchorEl: null,
            loading: true,
            filterParams: {},
            allColumns: (process.env.ORGANIZATION ==='Traders Agency') ? TableConfig.TA.cols : TableConfig.LM.cols,
            pagination: {
                size: 25,
                page: 0,
                totalCount: 0
            },
            sort: {
                asc: false,
                column: 'media_buy_id'
            }
        };

        this.updateTable = this.updateTable.bind(this);
    }

    componentDidMount() {

    }

    showAssets(data) {
        this.setState({
            showAssetsPopup: true,
            activeRow: data,
        })
    }

    showEditCosts(data) {
        this.setState({
            showUpdatePopup: true,
            activeRow: data,
        })
    }

    async loadData() {
        const serialize = (obj, prefix) => {
            var k, p, str, v;
            str = [];
            for (p in obj) {
                v = obj[p];
                k = prefix ? prefix + "[" + p + "]" : p;
                if (typeof v === "object") {
                    str.push(serialize(v, k));
                } else {
                    if(typeof(v) !== 'undefined' && v !== null && v.toString().length > 0) {
                        str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
                    }
                }
            }
            return str.join("&");
        };
        this.setState({
            ...this.state,
            loading: true
        })
        const {data} = await axios.get(
            process.env.SERVER_ENDPOINT + `/mediaBuys?${serialize(this.state.filterParams)}&page=${this.state.pagination.page + 1}&size=${this.state.pagination.size}&orderBy=${this.state.sort.column}&orderDir=${this.state.sort.asc ? 'asc' : 'desc'}`
        );
        const media_buys = _.map(data.data, itm => ({
            ...itm,
            margin: itm.vendor_budget - itm.lm_cost
        }))
        data.meta.page--;

        let hiddenCols = Object.assign([], this.state.defaultHiddenColumns);
        let hiddenColumnsConfig = localStorage.getItem(`hiddenColumns${document.location.pathname}`);
        if(hiddenColumnsConfig !== null) {
            hiddenCols = JSON.parse(hiddenColumnsConfig);
        }

        this.setState({
            ...this.state,
            data: media_buys,
            pagination: data.meta,
            loading: false,
            defaultHiddenColumns: hiddenCols
        });
    }

    sortBy(col) {
        let direction = this.state.sort.asc;
        let column = this.state.sort.column;

        if(col === this.state.sort.column) {
            direction = !this.state.sort.asc;
        } else {
            column = col;
        }

        this.setState({
            ...this.state,
            sort: {
                column: column,
                asc: direction
            }
        }, this.loadData);
    }

    changeRowsCount(event, count) {
        let pagination = {...this.state.pagination};
        pagination.page = 0;
        pagination.size = parseInt(count.key);
        this.setState({
            ...this.state,
            pagination: pagination
        }, this.loadData);
    }

    changePage(event, page) {
        let pagination = {...this.state.pagination};
        pagination.page = page;

        this.setState({
            ...this.state,
            pagination: pagination
        },  () => {
            this.loadData();
        })
    }

    isColDisabled(col) {
        return this.state.defaultHiddenColumns.indexOf(col) > -1;
    }

    toggleColumn(col, value) {
        let defCols = [...this.state.defaultHiddenColumns];
        if(value) {
            defCols.splice(defCols.indexOf(col), 1);
        } else {
            defCols.unshift(col);
        }
        this.setState({
            ...this.state,
            defaultHiddenColumns: defCols
        });

        localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(defCols) );
    }

    switchColPreset(param) {
        let hiddenCols = [];
        if(param ==='default') {
            hiddenCols = [...hiddenColsDefault];
        } else if(param === 'none') {
            hiddenCols = this.state.allColumns.map(col => col.fieldName);
        }

        localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(hiddenCols) );

        this.setState({
            ...this.state,
            defaultHiddenColumns: hiddenCols
        })
    }

    toggleColsSettings(event) {
        this.setState({
            ...this.state,
            columnsAnchorEl: event.currentTarget,
            colsSettingsOpened: !this.state.colsSettingsOpened
        })
    }

    format(value, type) {
        const isEmpty = val => {
            return !val || val === null || (val.length && val.length == 0) ? true : false;
        }

        let formatted;
        switch(type) {
            case 'date':
                return (
                    <span style={{textAlign: 'center', display: 'block', width: '100%'}}>{isEmpty(value) ? '-' : moment(value).format('MM/DD/YYYY')}</span>
                )
            case 'month':
                return (
                    <span style={{textAlign: 'center', display: 'block', width: '100%'}}>{isEmpty(value) ? '-' : moment().month(value.toString() ).add('months', -1).format('MMMM')}</span>
                )
            case 'money':
                if(isEmpty(value) ) return (
                    <span style={{display: 'inline-block', width: '100%', textAlign: 'right'}}>-</span>
                )

                formatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value);
                formatted = formatted.replace('$', '');
                return (
                    <div style={{
                        display: 'flex'
                    }}>
                        <>
                            <span>$</span>
                            <span style={{marginLeft: 'auto'}}>{formatted}</span>
                        </>
                    </div>)

            case 'margin':
                if(isEmpty(value) ) return (
                    <span style={{display: 'inline-block', width: '100%', textAlign: 'right'}}>-</span>
                )
                formatted = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value);

                formatted = formatted.replace('$', '');
                let fragment;
                if(formatted.startsWith('-') ) {
                    formatted = formatted.replace('-', '');
                    fragment = (
                        <>
                            <span>($</span>
                            <span style={{marginLeft: 'auto'}}>{formatted})</span>
                        </>
                    )
                } else {
                    fragment = (
                        <>
                            <span>$</span>
                            <span style={{marginLeft: 'auto'}}>{formatted}</span>
                        </>   
                    )
                }
                return (
                <div style={{
                    display: 'flex',
                    color: value < 0 ? 'rgb(188, 69, 69)' : 'rgb(49, 168, 90)'
                }}>
                    {fragment}
                </div>)
            case 'number':
                return (<span style={{textAlign: 'center', display: 'block', width: '100%'}}>{isEmpty(value) ? '-' : value}</span>)
        
            default:
                return (<span style={{display: 'inline-block', width: '100%', textAlign: 'center'}}>{isEmpty(value) ? '-' : value}</span>);
        }
    }

    goToMediaBuy(itm) {
        window.location.href = `/media-planner/request?id=${itm.media_buy_id}`;
    }

    colClickMethod(params, col) {
        eval(`this.${col.onClick}` ).call(this, params);
    }

    handleSelect(params) {
        this.setState({
            ...this.state,
            filterParams: params
        }, this.loadData);
    }

    async statusChange(status) {
        await axios.post(process.env.SERVER_ENDPOINT + "/updateMediaBuyStatus", {
            media_buy_id: this.state.statusDialogData.media_buy_id,
            status: status.value
        });

        if (
            (this.state.statusDialogData.status !== "Approved - Active" && this.state.statusDialogData.status !== "Approved - Paused") &&
            (status.value === 'approved' || status.value === 'approved_paused') &&
            this.state.statusDialogData["send_vendor_emails"] === 1 &&
            (process.env.DISABLED_FEATURES.split(",")).indexOf(
                "SEND_VENDOR_EMAIL"
            ) === -1
        ) {
            this.service.sendEmail(this.state.statusDialogData);
        }

        let data = [...this.state.data];
        data.find(mb => mb.media_buy_id === this.state.statusDialogData.media_buy_id).status = status.label;
        this.setState({...this.state, data: data})



        this.closeStatusDialog();

    }

    closeStatusDialog() {
        this.setState({
            ...this.state,
            statusDialogOpened: false
        })
    }

    openStatusDialog(data) {
        this.setState({
            ...this.state,
            statusDialogOpened: true,
            statusDialogData: data
        })
    }

    getStatusButton(row) {
        let found = statusOptions.find(itm => itm.label === row.status);
        if(found) {
            return <button className={`mb_status mb_status-btn ${found.value}`} onClick={() => this.openStatusDialog(row)}>{found.label}</button>
        } else {
            return '-';
        }
    }
    updateTable(changes) {
        if (!changes) {
            this.setState({ showUpdatePopup: false })
        } else {
            const data = [...this.state.data];
            const elem = { ...this.state.activeRow, ...changes }
            const idx = data.findIndex(v => v.media_buy_id == elem.media_buy_id)
            data[idx] = elem
            this.setState({ data, showUpdatePopup: false })
        }
    }

    render() {
        const { data } = this.state;
        let headers = [
            ...this.state.allColumns,
            {
                label: "Edit cost",
                fieldName: "variable_cpl",
                render: (row) => row.variable_cpl == "Variable" ? 
                <span style={{textDecoration:'underline', cursor:'pointer', display:'block', textAlign:'center'}} onClick={()=>this.showEditCosts(row)}>
                    Edit cost
                    </span> : 
                    <span style={{display:'block', textAlign:'center'}}>-</span>
            }
        ];

        return (
            <>
                {this.state.showUpdatePopup && <MediaCostUpdatePopup
                    data={this.state.activeRow}
                    closePopup={this.updateTable}
                />}
                {this.state.showAssetsPopup && <AssetsPopup data={this.state.activeRow} closePopup={() => this.setState({ showAssetsPopup: false })} /> }
                <Dialog onClose={this.closeStatusDialog.bind(this)} aria-labelledby="Status" open={this.state.statusDialogOpened}>
                    <DialogTitle>Select Status</DialogTitle>
                    <List style={{padding: '10px 20px'}}>
                        {statusOptions.map((status) => (
                        <ListItem button onClick={() => this.statusChange(status)} key={`status_list_${status.value}`}>
                            <ListItemText>
                                {status.label}
                            </ListItemText>
                        </ListItem>
                        ))}
                    </List>
                </Dialog>
                <Table className="reports__table media__planner" stickyHeader>
                    <TableHead>
                        <TableRow className="reports__header-sticky">
                            <TableCell key={`col_title`} colSpan={headers.length}>
                                <div className="reports__header_container" style={{minWidth: '930px'}}>
                                <h3>Media Buys</h3>

                                <MediaBuysFilters onSelect={params => this.handleSelect(params) } />
                                <button className="colsSettingsBtn" aria-haspopup="true" onClick={event => this.toggleColsSettings(event)}>
                                    <img alt="" src="/icons/svg/settings.svg" />
                                </button>

                                <a className="btn createNew__btn" href="/media-planner/request">
                                    Create Media Buy
                                </a>

                                <Menu
                                    id="columns-menu"
                                        keepMounted
                                        anchorEl={this.state.columnsAnchorEl}
                                        open={Boolean(this.state.colsSettingsOpened)}
                                        onClose={this.toggleColsSettings.bind(this)}
                                    >
                                    <Typography style={{height: '30px'}}>
                                        <span style={{fontSize: '14px', float: 'right'}}>
                                            Select:
                                            <button onClick={() => this.switchColPreset('all')} className="filter_select-allnone">All</button>
                                            <button onClick={() => this.switchColPreset('none')} className="filter_select-allnone">None</button>
                                            <button onClick={() => this.switchColPreset('default')} className="filter_select-allnone">Default</button>
                                        </span>
                                    </Typography>

                                    {headers.map((d, j) => d ? (
                                        d.type !== 'template' ?
                                        <MenuItem onClick={() => this.toggleColumn(d.fieldName, (this.state.defaultHiddenColumns.indexOf(d.fieldName) > -1))} key={`select_col_key_${j}`} value={d.fieldName}>
                                            <Checkbox checked={!this.isColDisabled(d.fieldName)} value={d.fieldName} />
                                            {d.label}
                                        </MenuItem>
                                        : null
                                    ):null)}
                                </Menu>
                                {!this.state.loading ? 
                                <TablePagination
                                    style={{
                                        position: 'absolute',
                                        right: '190px',
                                        top: 0
                                    }}
                                    rowsPerPageOptions={[15, 25, 50, 100]}
                                    component="div"
                                    count={this.state.pagination.totalCount}
                                    rowsPerPage={this.state.pagination.size}
                                    page={this.state.pagination.page}
                                    onChangePage={this.changePage.bind(this)}
                                    onChangeRowsPerPage={this.changeRowsCount.bind(this)}
                                />
                                : null}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        {headers.map((col, i) => (
                            !this.isColDisabled(col.fieldName) && !this.state.loading ? 
                            <TableCell className={`results__col_${i} mb_list-head-cell`} key={`col_${i}`}>
                                {col.label !== 'Get Assets' ?
                                <TableSortLabel
                                    active={this.state.sort.column === col.fieldName}
                                    direction={this.state.sort.asc ? 'asc' : 'desc'}
                                    onClick={() => this.sortBy(col.fieldName)}
                                >
                                    {col.label}
                                </TableSortLabel>
                                : col.label}
                            </TableCell>
                            : null
                        ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {!data || this.state.loading ? (
                        <TableRow style={{backgroundColor: '#fff'}}>
                            <TableCell colSpan={10}>
                                <Loading/>
                            </TableCell>
                        </TableRow>
                        
                    ) : (
                    this.state.data.map((row, i) => (
                        <TableRow key={i}>
                            {headers.map((col, j) => (
                                !this.isColDisabled(col.fieldName) ? 
                                <TableCell onClick={col.onClick ? () => this.colClickMethod(row, col) : null} style={{...col.style, textDecoration: col.onClick ? 'underline': 'none', cursor: col.onClick ? 'pointer': 'inherit'}} key={`_col_row_${j}_${i}`}>
                                    {col.fieldName === 'status' ?
                                        this.getStatusButton(row) 
                                    :
                                        <>
                                            {col.default ? col.default :
                                                col.render ? col.render(row) :
                                                    this.format(row[col.fieldName], col.type)}
                                        </>
                                    }
                                </TableCell>
                                : null
                            ))}
                        </TableRow>
                    ))
                    )}
                    </TableBody>
                </Table>
                <ScrollTopLeft />
            </>
        );
    }
}

export default MediaBuys;
