import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import MediaBuys from "../../components/Dashboards/MediaBuys";
import { navigate } from "gatsby";
import {checkAuth} from "../../helpers/auth";

class Dashboard extends React.Component {
  componentDidMount() {
    checkAuth('media-planner');
  }

  render() {
    return (
      <Layout>
        <SEO title="Welcome" />
        <Grid container style={style.mainContainer}>
          <Grid container justify="center">
            <div style={style.tabContainer}>
              <MediaBuys />
            </div>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

export default Dashboard;
